<template>
  <BlokkliProvider
    v-slot="{ entity, isEditing }"
    v-bind="blokkli"
    :entity="props"
  >
    <ContentHeader
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
      :media="entity?.mainImage || mainImage"
      :easy-to-read-url="easyToReadUrl?.path"
    />

    <!-- Rendered when the nesting level is lower than 4. This will render grouped menu links. -->
    <template v-if="nestingLevel < 4 && menuSections.length">
      <LayoutSection
        v-for="(child, i) in menuSections"
        :key="i"
        :title="child.link.label"
        padding
      >
        <MenuOrganization :links="child.subtree" :show-more-enabled="i > 0" />
      </LayoutSection>
    </template>
    <!-- Render the normal menu (like on topic pages). -->
    <Container v-else-if="organizationMenuChildren?.length">
      <MenuOrganization :links="organizationMenuChildren" />
    </Container>

    <BlokkliField
      v-slot="{ items }"
      :list="paragraphs"
      name="field_paragraphs"
      :allowed-fragments="['zuzug', 'sound_of_religion']"
    >
      <TableOfContents :paragraph-items="items" :is-editing="isEditing" />
    </BlokkliField>

    <BoxSection v-if="organization || lastUpdate?.first">
      <BoxSectionItemOrganization
        :organization="organization"
        class="row-span-2"
      />
      <BoxSectionItemUpdate :date="lastUpdate?.first" />
    </BoxSection>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeOrganizationPageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  easyToReadUrl?: NodeOrganizationPageFragment['easyToReadUrl']
  lastUpdate?: NodeOrganizationPageFragment['lastUpdate']
  paragraphs?: NodeOrganizationPageFragment['paragraphs']
  mainImage?: NodeOrganizationPageFragment['mainImage']
  organization?: NodeOrganizationPageFragment['organization']
  organizationMenuChildren?: NodeOrganizationPageFragment['organizationMenuChildren']
  canEdit?: NodeOrganizationPageFragment['canEdit']
}>()

const blokkli = buildBlokkliProps(props, 'organization_page')

const breadcrumbLinks = useBreadcrumbLinks()

// This is not a computed property on purpose, because we don't want it to be
// reactive. If it were reactive and the user navigates away from this page,
// the value would update using the breadcrumb length of the next page.
const nestingLevel = breadcrumbLinks.value.length

/**
 * Determine the menu sections.
 *
 * Prevents rendering empty sections with no menu links.
 */
const menuSections = computed(
  () => props.organizationMenuChildren?.filter((v) => !!v.subtree.length) || [],
)

useCDNHeaders((v) => v.addTags(['nuxt:page:organization_page']))
</script>
